import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationMessageOverlayView } from '@/components/Elements/MessageOverlayView';
import { TRANSLATION_LIST_VIEW_TYPE } from '@/constants';
import useOpen from '@/hooks/useOpen';

import { CampaignDialog } from './banner/CampaignDialog';
import { ShareUrlInvalidDialog } from './ShareUrlInvalidDialog';

/**
 * 共有終了ビュー プロパティ
 */
type Props = {
  // シリアルのモード
  isCampaign: boolean;
};

/**
 * 共有終了ビュー
 */
export const ShareEndView = React.memo(({ isCampaign }: Props) => {
  const { isOpen, onClose } = useOpen(true);
  const {
    isOpen: isOpenShareUrlInvalidDialog,
    onClose: onCloseShareUrlInvalidDialog,
  } = useOpen(true);
  const { t } = useTranslation();

  return (
    <div>
      {/* キャンペーンダイアログを表示 */}
      <CampaignDialog isOpen={isOpen && isCampaign} onClose={onClose} />

      {/* 共有URL無効ダイアログを表示 */}
      <ShareUrlInvalidDialog
        isOpen={isOpenShareUrlInvalidDialog}
        onClickOKButton={onCloseShareUrlInvalidDialog}
      />

      {/* メッセージオーバーレイビューを表示 */}
      <TranslationMessageOverlayView
        isOpen
        text={t('shareGuest.共有が終了しました')}
        translationListViewType={TRANSLATION_LIST_VIEW_TYPE.CONFERENCE}
      />
    </div>
  );
});
