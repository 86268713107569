import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ViewConfigStatus, VIEW_CONFIG_STATUS } from '@/constants/firestore';
import {
  SHARE_VIEW_STATUS,
  ShareViewInfoState,
  ShareViewStatus,
  shareViewInfoSlice,
} from '@/states/slices/shareViewInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 共有画面に関する情報を保存 hooks
 *
 * @returns
 */
export const useShareViewInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setShareViewStatus,
    setShareURLKey,
    setIsCSVDownloadGuest,
    setSharePauseResumeStatus,
    setUid,
    setIsOpenShareDialog,
    setIsOpenShareRejoinDialog,
    setIsOpenSharePauseCompleteDialog,
    setIsOpenShareResumeCompleteDialog,
    resetToInitialState,
  } = shareViewInfoSlice.actions;

  const {
    shareViewStatus,
    shareURLKey,
    isCSVDownloadGuest,
    sharePauseResumeStatus,
    uid,
    isOpenShareDialog,
    isOpenShareRejoinDialog,
    isOpenSharePauseCompleteDialog,
    isOpenShareResumeCompleteDialog,
  } = useSelector<RootState, ShareViewInfoState>(
    (state) => state.shareViewInfo,
  );

  /**
   * 共有画面の共有状況を更新
   */
  const changeShareViewStatus = useCallback(
    (value: ShareViewStatus) => {
      dispatch(setShareViewStatus(value));
    },
    [dispatch, setShareViewStatus],
  );

  /**
   * 共有画面のURLキーを更新
   */
  const changeShareURLKey = useCallback(
    (value: string) => {
      dispatch(setShareURLKey(value));
    },
    [dispatch, setShareURLKey],
  );

  /**
   * ゲスト画面のCSVダウンロード許可値を更新
   */
  const changeIsCSVDownloadGuest = useCallback(
    (value: boolean) => {
      dispatch(setIsCSVDownloadGuest(value));
    },
    [dispatch, setIsCSVDownloadGuest],
  );

  /**
   * 共有画面の一時停止/再開の状態を更新
   */
  const changeSharePauseResumeStatus = useCallback(
    (value: ViewConfigStatus) => {
      dispatch(setSharePauseResumeStatus(value));
    },
    [dispatch, setSharePauseResumeStatus],
  );

  /**
   * FirestoreのドキュメントIDを更新
   */
  const changeUid = useCallback(
    (value: string) => {
      dispatch(setUid(value));
    },
    [dispatch, setUid],
  );

  /**
   * 共有ダイアログを表示するか否か
   */
  const changeIsOpenShareDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareDialog(value));
    },
    [dispatch, setIsOpenShareDialog],
  );

  /**
   * 共有復帰ダイアログを表示するか否か
   */
  const changeIsOpenShareRejoinDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareRejoinDialog(value));
    },
    [dispatch, setIsOpenShareRejoinDialog],
  );

  /**
   * 共有一時停止完了ダイアログを表示するか否かの判定値を更新
   */
  const changeIsOpenSharePauseCompleteDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenSharePauseCompleteDialog(value));
    },
    [dispatch, setIsOpenSharePauseCompleteDialog],
  );

  /**
   * 共有再開完了ダイアログを表示するか否かの判定値を更新
   */
  const changeIsOpenShareResumeCompleteDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareResumeCompleteDialog(value));
    },
    [dispatch, setIsOpenShareResumeCompleteDialog],
  );

  /**
   * 共有URLに関する全ての情報をリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 共有画面情報を更新して共有開始
   *
   * @param urlKey 共有画面のURLキー
   * @param isCsvDownload CSVダウンロード許可値
   * @param status 共有が一時停止中か否か
   */
  const startSharingWithUpdate = useCallback(
    async (
      urlKey: string,
      isCsvDownload: boolean,
      status: ViewConfigStatus,
    ) => {
      // 共有有効(開始)
      changeShareViewStatus(SHARE_VIEW_STATUS.SHARED);
      changeShareURLKey(urlKey);
      changeIsCSVDownloadGuest(isCsvDownload);
      changeSharePauseResumeStatus(status);
    },
    [
      changeIsCSVDownloadGuest,
      changeSharePauseResumeStatus,
      changeShareURLKey,
      changeShareViewStatus,
    ],
  );

  /**
   * 共有中か否か
   *
   * @returns 共有中の場合はtrue
   */
  const isShared = useMemo(() => {
    if (shareViewStatus === SHARE_VIEW_STATUS.SHARED) {
      return true;
    }

    return false;
  }, [shareViewStatus]);

  /**
   * 共有画面の状態が共有中か否か
   *
   * @returns true=共有中 / false=一時停止中
   */
  const isShareActive = useMemo(() => {
    if (sharePauseResumeStatus === VIEW_CONFIG_STATUS.ACTIVE) {
      return true;
    }

    return false;
  }, [sharePauseResumeStatus]);

  return {
    shareViewStatus,
    shareURLKey,
    isCSVDownloadGuest,
    sharePauseResumeStatus,
    uid,
    isOpenShareDialog,
    isOpenShareRejoinDialog,
    isOpenSharePauseCompleteDialog,
    isOpenShareResumeCompleteDialog,
    setShareViewStatus: changeShareViewStatus,
    setShareURLKey: changeShareURLKey,
    setIsCSVDownloadGuest: changeIsCSVDownloadGuest,
    setSharePauseResumeStatus: changeSharePauseResumeStatus,
    setUid: changeUid,
    setIsOpenShareDialog: changeIsOpenShareDialog,
    setIsOpenShareRejoinDialog: changeIsOpenShareRejoinDialog,
    setIsOpenSharePauseCompleteDialog: changeIsOpenSharePauseCompleteDialog,
    setIsOpenShareResumeCompleteDialog: changeIsOpenShareResumeCompleteDialog,
    resetState,
    startSharingWithUpdate,
    isShared,
    isShareActive,
  };
};
