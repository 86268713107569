import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';

/**
 * 連続使用監視時間(ミリ秒)
 */
const CONTINUOUS_TIME = {
  // 通常時
  NORMAL: 5400000,
  // forスクール用シリアル利用時
  SCHOOL: 3600000,
} as const;

/**
 * 時間が経過したら実行したい関数の型
 */
export type ContinuousTimeOverFunction = () => void;

/**
 * 連続使用監視 カスタムフック
 *
 * ※
 * 連続使用かはWebsocket(音声ストリーム)への接続が開始されてからの経過時間で判断
 */
export const useContinuousTimer = () => {
  const timeOverCallbackIdsRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { isSchoolMode } = useBrowserUserInfo();

  /**
   * 連続使用時間を特定する
   */
  const continuousTime = useMemo(() => {
    if (isSchoolMode) {
      return CONTINUOUS_TIME.SCHOOL;
    }

    return CONTINUOUS_TIME.NORMAL;
  }, [isSchoolMode]);

  /**
   * setTimeoutを登録して時間が経過したらプロパティに指定された関数を実行
   */
  const observe = useCallback(
    (onContinuousFun?: ContinuousTimeOverFunction) => {
      const timerId: NodeJS.Timeout = setTimeout(
        () => {
          if (onContinuousFun) {
            onContinuousFun();
          }
        },
        // forスクール用の場合は60分、それ以外は90分の連続使用時間とする
        continuousTime,
      );
      timeOverCallbackIdsRef.current = timerId;
    },
    [continuousTime],
  );

  /**
   * 登録済のsetTimeoutを削除
   */
  const disconnect = useCallback(() => {
    // setTimeoutをキャンセル
    clearTimeout(timeOverCallbackIdsRef.current);
    timeOverCallbackIdsRef.current = undefined;
  }, []);

  /**
   * 登録済のsetTimeoutを削除して
   * 新しくsetTimeoutを登録する
   */
  const reRegisterObserve = useCallback(
    (onContinuousFun?: ContinuousTimeOverFunction) => {
      // 登録済の監視削除
      disconnect();
      // 新規監視
      observe(onContinuousFun);
    },
    [disconnect, observe],
  );

  /**
   * アンマウント時の処理
   */
  useEffect(
    () => () => {
      // 監視削除
      disconnect();
    },

    // アンマウント時の1度のみ実行したいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    // 連続使用時間
    continuousTime,
    // setTimeoutを削除
    disconnectContinuous: disconnect,
    // setTimeoutを再登録
    reObserveContinuous: reRegisterObserve,
  };
};
