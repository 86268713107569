import React, { useEffect } from 'react';

import { ContentLayout } from '@/components/Layout';
import { FONT_SIZE_INFO } from '@/constants';
import { ContractRenewalDialog } from '@/features/contract';
import {
  AccessExpiredDialog,
  FreeExpiredDialog,
  LicenseInvalidDialog,
  useNeedAgreement,
  usePtidExpired,
} from '@/features/expired';
import { LanguageSelectDialog } from '@/features/selectlanguage';
import { TranslationListView } from '@/features/translationlist';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useDisplayFooter } from '@/hooks/useDisplayFooter';
import { useShareViewEndInfo } from '@/hooks/useShareViewEndInfo';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import {
  MIC_STATUS,
  STT_ERROR_TYPE,
  STT_STATUS,
} from '@/states/slices/translationInfoSlice';

import { useRemainingTime } from '../hooks/useRemainingTime';

import { ContinuousErrorDialog } from './error/ContinuousErrorDialog';
import { NoSoundErrorDialog } from './error/NoSoundErrorDialog';
import { RetryErrorDialog } from './error/RetryErrorDialog';
import { SilenceErrorDialog } from './error/SilenceErrorDialog';
import { TranslationFooter } from './footer/TranslationFooter';
import { ShareInvalidDialog } from './share/ShareInvalidDialog';
import { ShareRejoinDialog } from './share/ShareRejoinDialog';

/**
 * プロパティ
 */
type Props = TranslationListView & {
  // [STT ON/OFF]ボタンがクリックされた場合の処理
  onClickSttButton: () => void;
};

/**
 * 翻訳画面ビュー
 *
 * @param param0
 * @returns
 */
export const TranslationView = React.memo(
  ({
    interimContent,
    recognizedList,
    displayType,
    onClickSttButton,
  }: Props) => {
    const { sttErrorType, isFreePlan, micStatus, sttStatus, isSharingDisplay } =
      useTranslationInfo();
    const { needsLoginAgain, openLoginAgainDialog, shouldDisplayFooter } =
      useTranslationDisplay();
    const { fontSize } = useBrowserTranslationDisplay();
    const { ptidExpired } = usePtidExpired();
    const { needAgreement } = useNeedAgreement();
    const {
      isOpenShareRejoinDialog,
      setIsOpenShareRejoinDialog,
      isShared,
      isShareActive,
    } = useShareViewInfo();
    const { isOpenShareInvalidDialog, setIsOpenShareInvalidDialog } =
      useShareViewEndInfo();

    // フッターの表示/非表示制御
    useDisplayFooter();
    // 翻訳画面表示時にお試しプランの場合のみ残時間取得
    useRemainingTime();

    useEffect(() => {
      if (sttErrorType === STT_ERROR_TYPE.PTID_EXP) {
        // PTID認証失敗
        ptidExpired();

        return;
      }

      // 利用規約画面
      if (sttErrorType === STT_ERROR_TYPE.NEED_AGREEMENT) {
        needAgreement();
      }
    }, [needAgreement, onClickSttButton, ptidExpired, sttErrorType]);

    return (
      <>
        {/* 画面初回表示時、言語選択ダイアログを表示 */}
        <LanguageSelectDialog
          isOpen={
            (isSharingDisplay || micStatus === MIC_STATUS.SUCCESS) &&
            sttStatus === STT_STATUS.INACTIVE
          }
          isHiddenCloseButton
          // OKボタン押下時にonCloseに指定した処理が実行される
          // 本来クローズ処理だがSTTが準備→開始されたらsttStatusがINACTIVEでは無くなるので言語選択ダイアログが閉じる
          onClose={onClickSttButton}
          // ダイアログで言語が変更されたらローカルストレージに即時保存する(STT開始時に選択された言語が使われるようにするため)
          isSaveOnSelectLang
        />
        {/* リトライ失敗エラーダイアログを表示 */}
        <RetryErrorDialog isOpen={sttErrorType === STT_ERROR_TYPE.OTHER} />
        {/* 無料期間終了ダイアログを表示 */}
        <FreeExpiredDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.FREE_EXP && !needsLoginAgain}
          onPurchaseFunction={openLoginAgainDialog}
        />
        {/* 契約状態更新後の再ログインダイアログ */}
        <ContractRenewalDialog isOpen={needsLoginAgain} />
        {/* ライセンス不正ダイアログを表示 */}
        <LicenseInvalidDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.LICENSE_EXP}
        />
        {/* アクセス期限切れダイアログを表示 */}
        <AccessExpiredDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.ACCESS_EXP}
        />
        {/* 5分無言エラーダイアログを表示 */}
        <SilenceErrorDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.SILENCE}
          startSTT={onClickSttButton}
        />
        {/* 連続使用エラーダイアログを表示 */}
        <ContinuousErrorDialog
          isOpen={sttErrorType === STT_ERROR_TYPE.CONSECUTIVELY}
          startSTT={onClickSttButton}
        />
        {/* 音声信号無音エラーダイアログを表示 */}
        <NoSoundErrorDialog isOpen={sttErrorType === STT_ERROR_TYPE.NO_SOUND} />
        {/* 共有復帰ダイアログ */}
        <ShareRejoinDialog
          isOpen={
            (isSharingDisplay || micStatus === MIC_STATUS.SUCCESS) &&
            isShareActive &&
            isOpenShareRejoinDialog
          }
          onClickOK={() => setIsOpenShareRejoinDialog(false)}
        />
        {/* 共有終了ダイアログを表示 */}
        <ShareInvalidDialog
          isOpen={isOpenShareInvalidDialog}
          onClickOK={() => setIsOpenShareInvalidDialog(false)}
        />

        {/* ヘッダー・フッター */}
        <ContentLayout
          footerChildren={
            <TranslationFooter
              onClickSttButton={onClickSttButton}
              isDisplayFooter={shouldDisplayFooter}
            />
          }
        >
          {/* 同時通訳リストビュー */}
          <TranslationListView
            interimContent={interimContent}
            recognizedList={recognizedList}
            displayType={displayType}
            fontSize={fontSize}
            point={FONT_SIZE_INFO.point}
            isDisplayFooter={shouldDisplayFooter}
            isFreePlan={isFreePlan}
            isShared={isShared}
          />
        </ContentLayout>
      </>
    );
  },
);
