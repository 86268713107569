import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';
import { SerialMode } from '@/states/slices/browserUserInfoSlice';
import { convertObjectToSneakJson } from '@/utils';

/**
 * リクエストパラメータ
 */
export type GuestUpdateApiRequest = {
  // 共有画面のURLキー
  url_key: string;
  // 翻訳先言語
  destlang: string;
};

/**
 * 結果コード定数
 */
export const GUEST_UPDATE_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // URLキーが無効
  WARN_INVALID_URLKEY: 'WARN_INVALID_URLKEY',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type GuestUpdateApiResultCode =
  (typeof GUEST_UPDATE_API_RESULT_CODE)[keyof typeof GUEST_UPDATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isGuestUpdateApiResultCode = (
  resultCode: string,
): resultCode is GuestUpdateApiResultCode => {
  if (resultCode in GUEST_UPDATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type GuestUpdateApiResponse = {
  // 結果コード
  resultCode: GuestUpdateApiResultCode;
  // シリアルのモード
  mode: SerialMode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: GuestUpdateApiRequest,
): Promise<GuestUpdateApiResponse> => {
  // パラメータをスネークケースのJSONに変換
  const params = convertObjectToSneakJson(request);

  // リクエスト送信(POST)
  const response = browserAxios
    .post<GuestUpdateApiResponse>(BROWSER.API_URL.GUEST_UPDATE, params, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * ゲスト情報設定API(同通サーバ) リクエスト
 *
 * @returns
 */
export const guestUpdateApi = (
  request: GuestUpdateApiRequest,
): Promise<GuestUpdateApiResponse> => callApi(request);
