import { useCallback } from 'react';

import { ViewConfigStatus } from '@/constants/firestore';
import { CREDENTIALS_API_RESULT_CODE } from '@/features/api';
import { useShareViewEndInfo } from '@/hooks/useShareViewEndInfo';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

import { useFirestoreLoginUseToken } from './useFirestoreLoginUseToken';

/**
 * Firestoreへのログインに成功したら共有を開始 hooks
 */
export const useFirestoreLoginSharing = () => {
  const { signInFirestore } = useFirestoreLoginUseToken();

  const { setShareViewStatus, startSharingWithUpdate, setUid } =
    useShareViewInfo();
  const { resetShareViewInfoEndState } = useShareViewEndInfo();

  /**
   * Firestoreにログインして共有開始準備
   *
   * @param urlKey 共有画面のURLキー
   * @param isCsvDownload CSVダウンロード許可値
   * @param status 共有が一時停止中か否か
   */
  const setupShare = useCallback(
    async (
      urlKey: string,
      isCsvDownload: boolean,
      status: ViewConfigStatus,
    ): Promise<void> => {
      // 共有画面情報(終了用)をリセット
      resetShareViewInfoEndState();

      // Firestoreログイン
      const signInFirestoreResult = await signInFirestore();
      if (
        signInFirestoreResult.credentialsApiResultCode !==
        CREDENTIALS_API_RESULT_CODE.OK
      ) {
        // 共有失敗
        setShareViewStatus(SHARE_VIEW_STATUS.SHARE_INFO_ERROR);

        return;
      }

      // firestoreTokenをReduxに保存
      setUid(signInFirestoreResult.uid);

      // Reduxの共有情報を更新して共有開始
      startSharingWithUpdate(urlKey, isCsvDownload, status);
    },
    [
      resetShareViewInfoEndState,
      setShareViewStatus,
      setUid,
      signInFirestore,
      startSharingWithUpdate,
    ],
  );

  return {
    setupShare,
  };
};
