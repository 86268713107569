import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FONT_SIZE_INFO,
  LIST_DISPLAY_TYPE,
  ListDisplayType,
} from '@/constants';
import { VIEW_CONFIG_STATUS } from '@/constants/firestore';

/**
 * ゲスト画面表示状況
 */
export const GUEST_STATUS_TYPE = {
  // 無し
  NONE: 'none',
  // 読み込み中
  LOADING: 'loading',
  // 成功
  SUCCESS: 'success',
  // ホストが共有を終了した
  SHARE_END: 'shareEnd',
  // URLキー不正エラー
  INVALID_URL_KEY: 'invalidUrlKey',
  // その他エラー
  OTHER: 'other',
};
export type GuestStatusType =
  (typeof GUEST_STATUS_TYPE)[keyof typeof GUEST_STATUS_TYPE];

export type ShareActiveStatus =
  (typeof VIEW_CONFIG_STATUS)[keyof typeof VIEW_CONFIG_STATUS];

/**
 * State
 */
export type GuestDisplayState = {
  // 翻訳元テキストの表示/非表示を管理
  displayType: ListDisplayType;
  // 翻訳関連テキストの文字サイズを管理
  fontSize: number;
  // 翻訳先言語
  destlang: string;
  // UI言語
  uiLang: string;
  // ゲスト画面表示状況
  guestStatus: GuestStatusType;
  // CSVダウンロードが許可されているか否か
  isCSVDownload: boolean;
  // 共有状況
  shareActiveStatus: ShareActiveStatus;
  // 共有再開ダイアログを表示するか否か
  isOpenShareActiveDialog: boolean;
  // 共有停止ダイアログを表示するか否か
  isOpenShareInactiveDialog: boolean;
  // キャンペーン関連を表示するか否か
  isCampaign: boolean;
};

/**
 * 初期State
 */
const initialState: GuestDisplayState = {
  displayType: LIST_DISPLAY_TYPE.list,
  fontSize: FONT_SIZE_INFO.defaultValue,
  destlang: '',
  uiLang: '',
  guestStatus: GUEST_STATUS_TYPE.NONE,
  isCSVDownload: false,
  shareActiveStatus: VIEW_CONFIG_STATUS.ACTIVE,
  isOpenShareActiveDialog: false,
  isOpenShareInactiveDialog: false,
  isCampaign: false,
};

/**
 * ゲスト画面情報管理 Slice
 */
export const guestDisplaySlice = createSlice({
  name: 'guestDisplay',
  initialState,
  reducers: {
    // 翻訳元テキストの表示/非表示を更新
    setDisplayType: (state, action: PayloadAction<ListDisplayType>) => {
      state.displayType = action.payload;
    },
    // 翻訳関連テキストの文字サイズを更新
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    // 翻訳先言語を更新
    setDestlang: (state, action: PayloadAction<string>) => {
      state.destlang = action.payload;
    },
    // UI言語を更新
    setUiLang: (state, action: PayloadAction<string>) => {
      state.uiLang = action.payload;
    },
    // ゲスト画面表示状況を更新
    setGuestStatus: (state, action: PayloadAction<GuestStatusType>) => {
      state.guestStatus = action.payload;
    },
    // CSVダウンロードが許可されているか否かの値を更新
    setIsCSVDownload: (state, action: PayloadAction<boolean>) => {
      state.isCSVDownload = action.payload;
    },
    // 共有状況の変更状態を更新
    setShareActiveChangeStatus: (
      state,
      action: PayloadAction<ShareActiveStatus>,
    ) => {
      state.shareActiveStatus = action.payload;
    },
    // 共有再開ダイアログを表示するか否かの値を更新
    setIsOpenShareActiveDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareActiveDialog = action.payload;
    },
    // 共有一時停止ダイアログを表示するか否かの値を更新
    setISOpenShareInactiveDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareInactiveDialog = action.payload;
    },
    // キャンペーン関連を表示するか否かの値を更新
    setISCampaign: (state, action: PayloadAction<boolean>) => {
      state.isCampaign = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
